<template>
  <transition name="fade">
    <div v-show="load">
      <component
        v-if="data.length > 0" 
        :is="layout" 
        :data="data"
      />
      <slot/>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Collection',
  components: {
    'masonry': () => import('@/components/layouts/Masonry.vue'),
    'grid': () => import('@/components/layouts/Grid.vue'),
    'filter-single': () => import('@/components/layouts/FilterSingle.vue')
  },
  data() {
    return {
      load: false,
      data: [],
    }
  },
  mounted() {
    this.$api
      .get(`${this.$route.params.type}?_sort=createdAt:desc`)
      .then(response => {
        this.data = response.data
        this.load = true
      })
      .catch(error => {
        console.log(error);
        this.$router.push({ name: 'Error', params: { error }})
      })
  },
  computed: {
    layout() {
      return this.$store.state.layout
    }
  },
}
</script>